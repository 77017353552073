// import Repository from '../../repositories/RepositoryFactory';
// const FilterBarRepository = Repository.get('filterbar');

export default {
	namespaced: true,
	state: {
		search: null,
		hospitalId: null,
		role: null,
		specialization: null,
		medicalInstitution: null,
		patients: null,
		userStatusVerifier: null,
		billingDate: null,
		systemAdminDate: null,
		selectedLogsDate: null,
		billingDataForHospitalAdmin: null,
		billingDataForOrganizationalAdmin: null,
		billingCsv: null,
		usersActivity: null,
		enableDatePicker: false,
		billingReportState: '',
	},
	mutations: {
		ENABLE_DATE_PICKER (state, payload) {
			state.enableDatePicker = payload;
		},
		SET_SEARCH (state, payload) {
			state.search = payload;
		},
		RESET_SEARCH (state) {
			state.search = null;
		},
		SET_ROLE (state, payload) {
			state.role = payload;
		},
		SET_SPECIALIZATIONS (state, payload) {
			state.specialization = payload;
		},
		SET_MEDICAL_INSTITUTION (state, payload) {
			state.medicalInstitution = payload;
		},
		SET_MEDICAL_TEAM (state, payload) {
			state.patients = payload;
		},
		SET_USER_STATUS_VERIFIER (state, payload) {
			state.userStatusVerifier = payload;
		},
		SET_BILLINGS_DATE (state, payload) {
			state.billingDate = payload;
		},
		SET_SYSTEM_ADMIN_DATE (state, payload) {
			state.systemAdminDate = payload;
		},
		SELECTED_LOGS_DATE (state, payload) {
			state.selectedLogsDate = payload;
		},
		BILLING_DATA_CSV (state, payload) {
			state.billingCsv = payload;
		},
		BILLING_DATA_HOSPITAL_ADMIN (state, payload) {
			state.billingDataForHospitalAdmin = payload;
		},
		BILLING_DATA_ORGANIZATIONAL_ADMIN (state, payload) {
			state.billingDataForOrganizationalAdmin = payload;
		},
		SET_USERS_ACTIVITY (state, payload) {
			state.usersActivity = payload;
		},
		SET_BILLING_REPORT_STATE (state, payload) {
			state.billingReportState = payload;
		},
		GET_HOSPITAL_ID (state, payload) {
			state.hospitalId = payload;
		},
	},
	actions: {
		async enableDatePicker ({ commit }, payload) {
			commit('ENABLE_DATE_PICKER', payload);
		},
		resetSearch ({ commit }) {
			commit('RESET_SEARCH');
		},
		async usersActivity ({ commit }, body) {
			commit('SET_USERS_ACTIVITY', body);
		},
		async AppBarSpacialization ({ commit }, body) {
			commit('SET_SPECIALIZATIONS', body);
		},
		async generateCSV ({ commit, state }, body) {
			// Check if state.billingDate is defined and not null
			const currentDate = new Date();
			const monthNumber = state.billingDate !== null ? parseInt(state.billingDate?.substring(5, 7)) : currentDate.getMonth() + 1;
			const yearNumber = state.billingDate !== null ? parseInt(state.billingDate?.substring(0, 4)) : currentDate.getFullYear();

			if (state.billingDate) {
				const selectedYear = parseInt(state.billingDate.substring(0, 4));
				const selectedMonth = parseInt(state.billingDate.substring(5, 7));
				const clientName = `Client ${body.organizationId}`; // state.loggedUser?.hospitalAssignedData?.hospitalName || state.loggedUser?.organizationAssignedData?.institutionName;
				const clientId = body.organizationId; // state.loggedUser?.hospitalAssignedData?.id || state.loggedUser?.organizationAssignedData?.id;

				const data = {
					clientName,
					clientId,
					selectedYear,
					selectedMonth,
				};

				await commit('BILLING_DATA_CSV', data);
			} else {
				const selectedYear = yearNumber; // state.billingDate.substring(0, 4);
				const selectedMonth = monthNumber; // state.billingDate.substring(5, 7);
				const clientName = `Client ${body.organizationId}`; // state.loggedUser?.hospitalAssignedData?.hospitalName || state.loggedUser?.organizationAssignedData?.institutionName;
				const clientId = body.organizationId; // state.loggedUser?.hospitalAssignedData?.id || state.loggedUser?.organizationAssignedData?.id;

				const data = {
					clientName,
					clientId,
					selectedYear,
					selectedMonth,
				};

				const billingDateFromCurrentDate = selectedYear + '-' + selectedMonth;
				commit('SET_BILLINGS_DATE', billingDateFromCurrentDate);

				await commit('BILLING_DATA_CSV', data);
			}
		},
		async billingDataForHospitalAdmin ({ commit, state }, body) {
			const currentDate = new Date();
			const monthNumber = state.billingDate !== null ? parseInt(state.billingDate?.substring(5, 7)) : currentDate.getMonth() + 1;
			const yearNumber = state.billingDate !== null ? parseInt(state.billingDate?.substring(0, 4)) : currentDate.getFullYear();

			const dataForBilling = {
				hospitalId: body.hospitalId,
				month: monthNumber, // state.billingDate?.substring(5, 7),
				year: yearNumber, // state.billingDate?.substring(0, 4),
			};
			await commit('BILLING_DATA_HOSPITAL_ADMIN', dataForBilling);
		},
		async billingDataForOrganizationalAdmin ({ commit, state }, body) {
			const currentDate = new Date();
			const monthNumber = state.billingDate !== null ? parseInt(state.billingDate?.substring(5, 7)) : currentDate.getMonth() + 1;
			const yearNumber = state.billingDate !== null ? parseInt(state.billingDate?.substring(0, 4)) : currentDate.getFullYear();

			const dataForBillingOrganizationAdmin = {
				organizationId: body.organizationId,
				month: monthNumber, // state.billingDate?.substring(5, 7),
				year: yearNumber, // state.billingDate?.substring(0, 4),
			};
			await commit('BILLING_DATA_ORGANIZATIONAL_ADMIN', dataForBillingOrganizationAdmin);
		},
		async AppBarMedicalTeamDoctor ({ commit }, body) {
			commit('SET_MEDICAL_TEAM', body);
		},
		async AppBarDate ({ commit }, body) {
			commit('SET_BILLINGS_DATE', body);
		},
		async billingReportState ({ commit }, body) {
			commit('SET_BILLING_REPORT_STATE', body);
		},
		async SystemAdminDate ({ commit }, body) {
			commit('SET_SYSTEM_ADMIN_DATE', body);
		},
		async selectedLogsDate ({ commit }, body) {
			commit('SELECTED_LOGS_DATE', body);
		},
		async selectedHospital ({ commit }, body) {
			commit('GET_HOSPITAL_ID', body);
		},
		async AppBarSearch ({ commit }, body) {
			commit('SET_SEARCH', body);
		},
		// async AppBarRole ({ commit }, body) {
		// 	commit('SET_ROLE', body);
		// 	// const res = await FilterBarRepository.updateUser(body);
		// 	// return res;
		// },
		async AppBarMedicalInstitution ({ commit }, body) {
			commit('SET_MEDICAL_INSTITUTION', body);
		},
		async AppBarUserStatusVerifier ({ commit }, body) {
			commit('SET_USER_STATUS_VERIFIER', body);
		},
		async AppBarRole ({ commit }, body) {
			commit('SET_ROLE', body);
		},
	},
	getters: {
		getBillingDate (state) {
			if (!state.billingDate) {
				const today = new Date();
				const year = today.getFullYear();
				const month = String(today.getMonth() + 1).padStart(2, '0'); // Pad month to 2 digits
				return `${year}-${month}`;
			}
			return state.billingDate;
		},
		getUsersActivity: (state) => state.usersActivity,
		getBillingDataForHospitalAdmin: (state) => state.billingDataForHospitalAdmin,
		getBillingCsv: (state) => state.billingCsv,
		getBillingDataOrganizational: (state) => state.billingDataForOrganizationalAdmin,
		getMedicalTeam (state) {
			return state.patients;
		},
		getSpecialization (state) {
			return state.specialization;
		},
		getSearch (state) {
			return state.search;
		},
		removeSearch (state) {
			const removeSearch = state.search = null;
			return removeSearch;
		},
		getRole (state) {
			return state.role;
		},
		getMedicalInstitution (state) {
			return state.medicalInstitution;
		},
		getUserStatusVerifier (state) {
			return state.userStatusVerifier;
		},
	},
};
